<template>
  <div>
    <div>
      <h3 class="card-label">
        {{ $t('sales_invoices.sales_invoices') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <b-tabs v-if="show" content-class="mt-3" class="nav-custom-link">
        <b-tab :title="$t('sales_invoices.basic_information')" @click="toggleTab('basic_information')">
          <div class="row">
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <b>{{ $t('sales_invoices.customer') }}:</b> <span>{{ customer.fullname }}</span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <p><b>{{ $t('mobile') }}: </b> {{ customer.mobile }}</p>
                    <p><b>{{ $t('email') }}: </b> {{ customer.email }}</p>
                    <p><b>{{ $t('tax_no') }}: </b> {{ customer.tax_register }}</p>
                  </div>
                  <div class="col-md-12 mb-5">
                    <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                      <h6 class="my-auto text-white">{{ $t('customers.balances') }}</h6>
                    </div>
                    <table class="table table-row-bordered">
                      <thead>
                      <tr>
                        <th>{{ $t('customers.balance') }}</th>
                        <th>{{ $t('customers.debit') }}</th>
                        <th>{{ $t('customers.currency') }}</th>
                        <th>{{ $t('customers.last_validated_date') }}</th>
                      </tr>

                      </thead>
                      <tbody>
                      <template v-if="customer.balances && customer.balances.length > 0">
                        <tr v-for="(row, index) in customer.balances" :key="index">
                          <td>{{ row.credit }}</td>
                          <td>{{ row.debit }}</td>
                          <td>{{ row.currency_name }}</td>
                          <td>{{ row.last_validated_date }}</td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td colspan="4">{{ $t('no_balances') }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <b-card no-body class="mt-5" v-if="data.customer_id && customer_address">
                <b-card-header header-tab="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-5 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('shipping_address') }}
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="row">

                    <div class="col-lg-12 mb-5">
                      <label>{{ $t('customer_address') }}</label>
                      <div class="input-group">
                        <span>{{ customer_address.label }}</span>
                      </div>

                    </div>
                    <div class="col-lg-12 mb-5">
                      <p><b>{{ $t('customers.country') }}:</b> {{ customer_address.country_name }}</p>
                      <p><b>{{ $t('customers.city') }}:</b> {{ customer_address.city_name }}</p>
                      <p><b>{{ $t('customers.state') }}:</b> {{ customer_address.state }}</p>
                      <p><b>{{ $t('customers.postal_code') }}:</b> {{ customer_address.postal_code }}</p>
                      <p><b>{{ $t('customers.address_1') }}:</b> {{ customer_address.address_1 }}</p>
                      <p><b>{{ $t('customers.address_2') }}:</b> {{ customer_address.address_2 }}</p>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <b>{{ $t('sales_invoices.invoice_code') }}: </b> <span>{{ data.invoice_code }}</span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <b>{{ $t('sales_invoices.reference_number') }}: </b> <span>{{ data.reference_number }}</span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <b>{{ $t('sales_invoices.invoice_date') }}: </b><span>{{ data.invoice_date }}</span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <b>{{ $t('sales_invoices.currency') }}: </b> <span>{{ data.currency_name }}</span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <b>{{ $t('sales_invoices.payment_terms') }}: </b> <span v-if="data.payment_terms != null">{{ data.payment_terms }} {{ $t('purchases_invoices.day_s') }}</span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <b>{{ $t('status') }}: </b> <span>{{ data.status_name }}</span>
                  </div>

                  <div v-if="user" class="col-lg-6 mb-5">
                    <b>{{ $t('sales_invoices.user_assigned_to_invoice') }}: </b> <span>{{ user.name }}</span>
                  </div>

                  <div v-if="data.is_zero_invoice" class="col-lg-6 mb-5">
                    <b>{{ $t('sales_invoices.zero_sales_invoice') }}</b>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <b>{{ $t('sales_invoices.deferred_invoice') }}:</b>
                    <span v-if="data.is_deferred">{{ $t('deferred') }}</span>
                    <span v-else>{{ $t('cash') }}</span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <b>{{ $t('is_apply_taxes_invoice') }}:</b>
                    <span v-if="data.is_apply_taxes_invoice">{{ $t('invoice_is_tax') }}</span>
                    <span v-else>{{ $t('invoice_is_non_tax') }}</span>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('sales_invoices.items') }}</h6>
              </div>
              <div class="table-responsive bg-white pb-5">
                <table class="table table-row-bordered table-custom-padding">
                  <thead>
                  <tr>
                    <th width="300px">{{ $t('sales_invoices.item') }}</th>
                    <th width="140px">{{ $t('sales_invoices.unit_price') }}</th>
                    <th width="140px">{{ $t('sales_invoices.unit') }}</th>
                    <th width="140px">{{ $t('sales_invoices.qty') }}</th>
                    <th width="140px" v-if="isApplyTaxesInvoice">{{ $t('sales_invoices.tax') }}</th>
                    <th width="140px">{{ $t('discount') }}</th>
                    <th width="80px" v-if="isApplyTaxesInvoice">{{ $t('subtotal_before_tax') }}</th>
                    <th width="80px" v-if="isApplyTaxesInvoice">{{ $t('sales_invoices.amount_tax') }}</th>
                    <th width="80px">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                    <th width="80px">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in items_list" :key="index">
                    <td>{{ row.description }}</td>
                    <td>{{ row.unit_price }}</td>
                    <td>
                      <span>{{ (row.unit && row.unit.name) ? row.unit.name : '' }}</span>
                      <span v-if="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id">{{ row.units_number }}</span>
                    </td>
                    <td>
                      {{ row.qty }}
                    </td>
                    <td v-if="isApplyTaxesInvoice">
                      <template v-if="row.tax_applied && row.tax_applied.length > 0">
                        <span v-for="(_row, _index) in row.tax_applied" :key="'tax'+_index">{{ _row.name }}</span>
                      </template>
                    </td>
                    <td>
                      <span>{{ row.discount_value }} {{ row.discount_type == 2 ? '%' : '' }}</span>
                    </td>
                    <td v-if="isApplyTaxesInvoice">{{ row.subtotal_before_tax }}</td>
                    <td v-if="isApplyTaxesInvoice">{{ row.amount_tax }}</td>
                    <td>{{ row.subtotal_before_discount && row.subtotal_before_discount > 0 ? parseToShortFloat(row.subtotal_before_discount) : 0 }}</td>
                    <td>{{ row.subtotal_after_discount && row.subtotal_after_discount > 0 ? parseToShortFloat(row.subtotal_after_discount) : 0 }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td :colspan="isApplyTaxesInvoice ? '6' : '3'"></td>
                    <th colspan="2">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                    <th colspan="2"><span>{{ data.total_amount_before_discount && data.total_amount_before_discount > 0 ? parseToShortFloat(data.total_amount_before_discount) : '0' }} {{ currency_code }}</span></th>
                  </tr>
                  <tr>
                    <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '3'"></td>
                    <th colspan="2">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                    <th colspan="2"><span>{{ data.total_amount_after_discount && data.total_amount_after_discount > 0 ? parseToShortFloat(data.total_amount_after_discount) : '0' }} {{ currency_code }}</span></th>
                  </tr>
                  <tr>
                    <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '3'"></td>
                    <th colspan="2">{{ $t('sales_invoices.total_discount') }}</th>
                    <th colspan="2"><span>{{ data.invoice_total_discount ? data.invoice_total_discount : '0' }} {{ currency_code }}</span></th>
                  </tr>
                  <tr>
                    <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '3'"></td>
                    <th colspan="2">{{ $t('sales_invoices.total_after_shipping') }}</th>
                    <th colspan="2"><span>{{ data.shipping_fees ? data.shipping_fees : '0' }} {{ currency_code }}</span></th>
                  </tr>
                  <tr v-if="isApplyTaxesInvoice">
                    <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '3'"></td>
                    <th colspan="2">{{ $t('sales_invoices.total_tax') }}</th>
                    <th colspan="2"><span>{{ data.invoice_total_taxes ? data.invoice_total_taxes : '0' }} {{ currency_code }}</span></th>
                  </tr>
                  <tr>
                    <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '3'"></td>
                    <th colspan="2">{{ $t('sales_invoices.final_total') }}</th>
                    <th colspan="2"><span>{{ data.invoice_total }} {{ currency_code }}</span></th>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <div class="mt-5">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('sales_invoices.notes') }}</label>
                  <p>{{data.notes}}</p>
                </div>
              </div>
            </div>
          </div>


        </b-tab>

      </b-tabs>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import dataTypeNumbers from "@/core/config/mix/dataTypeNumbers";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "View-general-sales-invoice",
  data() {
    return {
      mainRoute: 'sales/general_sales',
      data: {
        invoice_code: null,
        invoice_subtotal: 0,
        invoice_total: 0,
        invoice_total_discount: 0,
        invoice_total_shipping: 0,
        invoice_total_taxes: 0,
        total_amount_before_discount: 0,
        total_amount_after_discount: 0,
        invoice_total_before_taxes: 0,
        invoice_total_after_taxes: 0,
        shipping_details: 1,
        shipping_fees: null,
        // adjustment: null,
        customer_id: null,
        discount_type: 1,
        discount_value: null,
        currency_id: null,
        status: 1,
        status_name: null,
        currency_name: null,
        invoice_date: null,
        notes: null,
        adjustment_value: null,
        deposite_value: null,
        payment_terms: null,
        // issue_date: null,
        interested_user_id: null,
        account_id: null,
        // branch_id: null,
        // exchange_rate: null,
        price_list_id: null,
        reference_number: null,
        apply_tax_rules: [],

        initial_payment_method: 1,
        initial_payment_amount: null,
        initial_payment_method_type_type: null,
        initial_payment_method_type_id: null,
        initially_paid: 0,
        is_paid: null,
        is_confirmed: false,
        is_zero_invoice: false,
        is_deferred: false,
        word_invoice_total: null,
        word_invoice_total_en: null,
        is_apply_taxes_invoice: false,
        total_out_tax: 0,
        total_tax_type_val: [],
      },
      key: 'GeneralSales',
      items_list: [],
      tab_name: 'basic_information',
      customer: null,
      price_list: [],
      account: null,
      bank: null,
      treasury: null,
      customer_address: null,
      user: null,
      currency_code: null,
      shipping_details_name: null,
      show: false,
    }
  },
  computed: {
    isApplyTaxesInvoice() {
      return this.data.is_apply_taxes_invoice;
    },
  },
  methods: {
    ...dataTypeNumbers,
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    async getData() {
      let _id = (this.$route.params.id ? this.$route.params.id : null);
      if (_id)
        await ApiService.get(`${this.mainRoute}/${_id}`).then((response) => {

          this.data.invoice_subtotal = response.data.data.invoice_subtotal;
          this.data.invoice_total = response.data.data.invoice_total;
          this.data.invoice_total_discount = response.data.data.invoice_total_discount;
          this.data.invoice_total_shipping = response.data.data.invoice_total_shipping;
          this.data.invoice_total_taxes = response.data.data.invoice_total_taxes;

          this.data.total_out_tax = response.data.data.total_out_tax ? response.data.data.total_out_tax : 0;
          this.data.total_tax_type_val = response.data.data.total_tax_type_val ? response.data.data.total_tax_type_val : [];

          this.data.total_amount_before_discount = response.data.data.total_amount_before_discount;
          this.data.total_amount_after_discount = response.data.data.total_amount_after_discount;

          this.data.invoice_total_before_taxes = response.data.data.invoice_total_before_taxes;
          this.data.invoice_total_after_taxes = response.data.data.invoice_total_after_taxes;

          this.data.shipping_details = response.data.data.shipping_details;
          this.data.shipping_fees = response.data.data.shipping_fees;
          this.data.adjustment_value = response.data.data.adjustment_value;
          this.data.customer_id = response.data.data.customer_id;
          this.data.discount_type = response.data.data.discount_type;
          this.data.discount_value = response.data.data.discount_value;
          this.data.currency_id = response.data.data.currency_id;
          this.data.invoice_date = response.data.data.invoice_date;
          this.data.notes = response.data.data.notes;

          this.data.deposite_value = response.data.data.deposite_value;
          this.data.payment_terms = response.data.data.payment_terms;
          this.data.account_id = response.data.data.account_id;
          this.customer = response.data.data.customer;

          this.data.price_list_id = response.data.data.price_list_id;
          this.price_list = response.data.data.price_list;
          this.items_list = response.data.data.items_list;
          this.account = response.data.data.account;

          this.data.reference_number = response.data.data.reference_number;
          this.data.apply_tax_rules = response.data.data.apply_tax_rules;

          this.data.initial_payment_method = response.data.data.initial_payment_method;
          this.data.initial_payment_amount = response.data.data.initial_payment_amount;
          this.data.treasury_id = response.data.data.treasury_id;
          this.data.initial_payment_method_type_type = response.data.data.initial_payment_method_type_type;
          this.data.initial_payment_method_type_id = response.data.data.initial_payment_method_type_id;

          this.data.word_invoice_total = response.data.data.word_invoice_total;
          this.data.word_invoice_total_en = response.data.data.word_invoice_total_en;
          this.data.is_apply_taxes_invoice = response.data.data.is_apply_taxes_invoice;

          this.data.initially_paid = response.data.data.initially_paid;
          this.data.is_paid = response.data.data.is_paid;
          this.data.is_confirmed = response.data.data.is_confirmed;
          this.data.status = response.data.data.status;
          this.data.status_name = response.data.data.status_name;
          this.data.currency_name = response.data.data.currency_name;

          this.bank = response.data.data.bank;
          this.treasury = response.data.data.treasury;


          this.data.interested_user_id = response.data.data.interested_user_id;

          this.data.is_zero_invoice = response.data.data.is_zero_invoice;
          this.data.is_deferred = response.data.data.is_deferred;


          this.data.invoice_code = response.data.data.invoice_code;
          this.currency_code = response.data.data.currency_code;

          this.show = true;

          this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.general_sales"), route: '/sales/general_sales'}, {title: this.data.invoice_code}]);

        });
    },
  },
  mounted() {
  },
  created() {
    this.getData();
  }
}
</script>

<style scoped>
b{
  font-weight: 600;
}
</style>